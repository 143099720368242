import { Button, Flex, Text } from "@chakra-ui/react"
import { useContext } from "react"
import { Link } from "react-router-dom"
import { AuthContext } from "../contexts/auth"

export const Header = () => {
  const { data, setToken } = useContext(AuthContext)

  return <Flex alignItems="center" justifyContent="center" wrap="wrap" w="full" rowGap={4} py={2} columnGap={10} backgroundColor="gray.100">
    <Text fontSize="4xl">
      <Link to="/">Grupalia</Link>
    </Text>

    {data
      ? <>
        <Text fontSize={20} marginLeft="3">{data.name}</Text>

        <Button marginLeft="3" onClick={() => setToken(null)}>Cerrar sesión</Button>

        {data.admin && <Button backgroundColor="blue.400" color="white" as={Link} to="/admin">Panel de adminisitración</Button>}
      </> : (
        <Button marginLeft="3" as={Link} to="/login">Login</Button>
      )
    }
  </Flex>
}