import { Flex, Text } from "@chakra-ui/react";
import { Outlet, Route, Routes } from "react-router-dom";
import { Header } from "./components/header";
import { Admin } from "./components/protected";
import { AuthProvider } from "./contexts/auth";
import { AdminPage } from "./pages/admin/page";
import { EditPage } from "./pages/edit";
import { FormPage } from "./pages/form";
import { HomePage } from "./pages/home";
import { LoginPage } from "./pages/login";
import { RegisterPage } from './pages/register';

function App() {
  return (
    <AuthProvider>
      <Flex justifyContent="center">
        <Flex direction="column" alignItems="center" rowGap={4} width="full">
          <Header />

          <Routes>
            <Route path="/" element={<HomePage />} />

            <Route path="/login" element={<LoginPage />} />
            <Route path="/register" element={<RegisterPage />} />

            <Route path="/form" element={<FormPage />} />
            <Route path="/edit/:id" element={<EditPage />} />

            <Route path="/admin" element={
              <Admin>
                <Text fontSize="2xl" marginBottom="2">Panel de adminisitración</Text>

                <Outlet />
              </Admin>
            }>
              <Route path="" element={<AdminPage />} />
            </Route>

            <Route path="*" element={<div>404 Not found</div>} />
          </Routes>
        </Flex>
      </Flex>
    </AuthProvider>
  );
}

export default App;
