import baseAxios from 'axios'
import { useContext, useEffect, useState } from 'react'
import { AuthContext } from '../contexts/auth'

export const axios = baseAxios.create({
  baseURL: process.env.REACT_APP_API_URL || "http://localhost:3001/"
})

export function useGet(url) {
  const auth = useContext(AuthContext)

  const [data, setData] = useState()

  useEffect(() => {
    axios.get(url, {
      headers: {
        Authorization: `Bearer ${auth.token}`
      }
    }).then(({ data }) => setData(data))
  }, [url, auth.token])

  return data
}
