import { Button, Flex, FormControl, FormErrorMessage, FormLabel, Input, Text } from "@chakra-ui/react"
import { useContext } from "react"
import { useForm } from 'react-hook-form'
import { Link, useNavigate } from "react-router-dom"
import { AuthContext } from "../contexts/auth"
import { axios } from "../lib/axios"

export const RegisterPage = () => {
  const navigate = useNavigate()

  const auth = useContext(AuthContext)

  const { register, handleSubmit, setError, formState: { errors } } = useForm({ mode: "all" })

  const onSubmit = async (data) => {
    try {
      const req = await axios.post("/auth/register", { name: data.name, email: data.email, password: data.password })

      auth.setToken(req.data.token)

      navigate("/")
    } catch (e) {
      setError("password", { type: "400" })
    }
  }

  return <Flex as="form" onSubmit={handleSubmit(onSubmit)} backgroundColor="gray.100" p={4} direction="column" width="full" maxW="lg" gap={3}>
      <Text fontSize="2xl">Register</Text>

      <FormControl isInvalid={errors.name}>
        <FormLabel>Nombre: </FormLabel>

      <Input backgroundColor="white" placeholder="Nombre" {...register("name", { required: true })} />

        {errors.name && <FormErrorMessage>El nombre es requerido</FormErrorMessage>}
      </FormControl>


      <FormControl isInvalid={errors.email}>
        <FormLabel>Email</FormLabel>

      <Input backgroundColor="white" type="email" placeholder="Email" {...register("email", { required: true })} />

        {errors.email && <FormErrorMessage>El email es requerido</FormErrorMessage>}
      </FormControl>

      <FormControl isInvalid={errors.password}>
        <FormLabel>Contraseña</FormLabel>


      <Input backgroundColor="white" type="password" placeholder="Contraseña"  {...register("password", { required: true, minLength: 6 })} />

        {errors.password && <FormErrorMessage>La contraseña es requerida y debe tener por lo menos 8 caracteres</FormErrorMessage>}
      </FormControl>

      <Button backgroundColor="blue.500" type="submit">Register</Button>
    <Button backgroundColor="gray.300" as={Link} to="/login">Login</Button>
  </Flex>
}