import { Button, Flex, FormControl, FormErrorMessage, FormLabel, Input, Text } from "@chakra-ui/react"
import { useContext } from "react"
import { useForm } from 'react-hook-form'
import { Link, useNavigate } from "react-router-dom"
import { AuthContext } from "../contexts/auth"
import { axios } from "../lib/axios"

export const LoginPage = () => {
  const navigate = useNavigate()

  const auth = useContext(AuthContext)

  const { register, handleSubmit, setError, formState: { errors } } = useForm({ mode: "all" })

  const onSubmit = async (data) => {
    try {
      const req = await axios.post("/auth/login", { email: data.email, password: data.password })

      auth.setToken(req.data.token)

      navigate("/")
    } catch (e) {
      setError("password", { type: "400" })
    }
  }

  return <Flex as="form" onSubmit={handleSubmit(onSubmit)} backgroundColor="gray.100" borderRadius={10} p={4} direction="column" width="full" maxW="lg" gap={3}>
      <Text fontSize="2xl">Login</Text>

      <FormControl isInvalid={errors.email}>
        <FormLabel>Email</FormLabel>

      <Input backgroundColor="white" type="email" placeholder="Email" {...register("email", { required: true })} />

        {errors.email && <FormErrorMessage>El email es requerido</FormErrorMessage>}
      </FormControl>

      <FormControl isInvalid={errors.password}>
      <FormLabel>Password</FormLabel>

      <Input backgroundColor="white" type="password" placeholder="Password"  {...register("password", { required: true, minLength: 6 })} />

        {errors.password && (
          <FormErrorMessage>
            {errors.password?.type === "required" && "La contraseña es requerida"}
            {errors.password?.type === "minLength" && "La contraseña debe tener por lo menos 6 caracteres"}
            {errors.password?.type === "400" && "Los datos no son correctos"}
          </FormErrorMessage>
        )}
      </FormControl>

      <Button backgroundColor="blue.500" type="submit">Login</Button>
    <Button backgroundColor="gray.300" as={Link} to="/register">Register</Button>
    </Flex>

}