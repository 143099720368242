import Cookies from "js-cookie";
import { createContext, useEffect, useState } from "react";
import { useJwt } from 'react-jwt';

export const AuthContext = createContext()

export const AuthProvider = ({ children }) => {
  const [token, setToken] = useState(
    Cookies.get("token")
  )
  const { decodedToken: data } = useJwt(
    token
  )

  useEffect(() => {
    if (token) {
      Cookies.set("token", token, { expires: 7 })
    }
    else {
      Cookies.remove("token")
    }

  }, [token])

  return <AuthContext.Provider value={{ token, setToken, data }}>
    {children}
  </AuthContext.Provider>
}