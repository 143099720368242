import { Button, Flex, FormControl, FormErrorMessage, FormLabel, Input, Select, Text, Textarea } from "@chakra-ui/react"
import { useContext } from "react"
import { useForm } from 'react-hook-form'
import { useNavigate } from "react-router-dom"
import { AuthContext } from "../contexts/auth"
import { axios } from "../lib/axios"

export const categories = [
  "Programación",
  "Deportes",
  "Cine",
  "Videojuegos",
  "Viajes",
  "Series",
  "Cocina",
  "Salud"
]

export const FormPage = () => {
  const navigate = useNavigate()

  const auth = useContext(AuthContext)

  const { register, handleSubmit, formState: { isValid, errors } } = useForm({ mode: "all" })

  const onSubmit = async (data) => {
    await axios.post("/groups", data, { headers: { Authorization: `Bearer ${auth.token}` } })

    navigate("/")
  }

  return <Flex as="form" onSubmit={handleSubmit(onSubmit)} backgroundColor="gray.100" p={4} direction="column" width="full" maxW="lg" gap={3}>
      <Text fontSize="2xl">Formulario</Text>

      <FormControl isInvalid={errors.name}>
        <FormLabel>Nombre: </FormLabel>

      <Input backgroundColor="white" placeholder="Nombre" {...register("name", { required: true, minLength: 2 })} />

        {errors.name?.type === "required" && <FormErrorMessage>El nombre es requerido</FormErrorMessage>}
        {errors.name?.type === "minLength" && <FormErrorMessage>El nombre debe tener por lo menos 2 caracteres</FormErrorMessage>}
      </FormControl>
      <FormControl isInvalid={errors.description}>
        <FormLabel>Descripción: </FormLabel>

      <Textarea backgroundColor="white" placeholder="Descripción" {...register("description", { required: true, minLength: 12 })} />

      <FormLabel fontSize="xs">Es importante indicar la plataforma del grupo</FormLabel>

        {errors.description?.type === "required" && <FormErrorMessage>La descripción es requerida</FormErrorMessage>}
        {errors.description?.type === "minLength" && <FormErrorMessage>La descripción debe tener por lo menos 12 caracteres</FormErrorMessage>}
      </FormControl>
      <FormControl isInvalid={errors.url}>
        <FormLabel>Url: </FormLabel>

      <Input backgroundColor="white" placeholder="Url" {...register("url", {
          required: true, pattern: new RegExp("^(http[s]?:\\/\\/(www\\.)?|ftp:\\/\\/(www\\.)?|www\\.){1}([0-9A-Za-z-\\.@:%_+~#=]+)+((\\.[a-zA-Z]{2,3})+)(/(.)*)?(\\?(.)*)?")
        })} />

        {errors.url?.type === "required" && <FormErrorMessage>La url es requerida</FormErrorMessage>}
        {errors.url?.type === "pattern" && <FormErrorMessage>La url es invalida</FormErrorMessage>}
      </FormControl>
      <FormControl isInvalid={errors.category}>
        <FormLabel>Categoría: </FormLabel>

      <Select backgroundColor="white"
          placeholder="Seleccione una categoría"
          {...register("category", { required: true })}
        >
          {
            categories.map(
              category => <option key={category} value={category.toLowerCase()}>{category}</option>
            )
          }
        </Select>

        {errors.category && <FormErrorMessage>La categoría es requerida</FormErrorMessage>}
      </FormControl>

      <Button disabled={!isValid} backgroundColor="blue.500" type="submit">Crear</Button>
    </Flex>

}