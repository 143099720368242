import { Button, Flex, IconButton, Text } from "@chakra-ui/react";
import { useContext } from "react";
import { MdEdit } from 'react-icons/md';
import { Link } from "react-router-dom";
import { AuthContext } from "../contexts/auth";
import { useGet } from "../lib/axios";

export const HomePage = () => {
  const { data } = useContext(AuthContext)

  const groups = useGet("/groups")

  return <>
    {data && <Button backgroundColor="blue.400" color="white" size="lg" my={10} as={Link} to="/form">Crear grupo</Button>}

    <Flex wrap="wrap" justifyContent="space-around" w="full" maxW="3xl" gap={2} mx={3}>
      {
        groups
          ? groups.map(group => (
            <Flex key={group._id} gap={2} direction="column" justifyContent="space-between" borderRadius={5} padding="4" backgroundColor="gray.100" w={250} height={250}>
              <Flex direction="column" >
                <Flex justifyContent="space-between" alignItems="center" mb={2}>
                  <Text textTransform="capitalize">{group.category}</Text>

                  {
                    group.user === data?._id && (
                      <IconButton backgroundColor="blue.400" color="white" size="sm" as={Link} to={`/edit/${group._id}`}>
                        <MdEdit size={20} />
                      </IconButton>
                    )
                  }
                </Flex>


                <Text fontSize="2xl" textAlign="center">{group.name}</Text>
                <Text fontSize="sm">{group.description}</Text>

              </Flex>

              <Button backgroundColor="gray.200" fontSize="lg" textAlign="center" as="a" href={group.url}>Enlace</Button>
            </Flex>
          )
          )
          : "Cargando..."
      }
    </Flex>
  </>
}
