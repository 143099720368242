import { useContext } from "react"
import { useNavigate } from "react-router-dom"
import { AuthContext } from "../contexts/auth"

export const Protected = ({ children }) => {
  const { data } = useContext(AuthContext)

  const navigate = useNavigate()

  if (!data) {
    navigate("/login")

    return null
  }

  return children
}

export const Admin = ({ children }) => {
  const { data } = useContext(AuthContext)

  const navigate = useNavigate()

  if (!data?.admin) {
    navigate("/")

    return null
  }

  return children
}