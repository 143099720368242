import { List, ListItem, Switch } from "@chakra-ui/react"
import { useContext, useEffect, useState } from "react"
import { AuthContext } from "../../contexts/auth"
import { axios, useGet } from "../../lib/axios"

export const GroupsList = () => {
  const auth = useContext(AuthContext)
  const initialGroups = useGet("/admin/groups")

  const [groups, setGroups] = useState()

  useEffect(() => {
    if (initialGroups) setGroups(initialGroups)
  }, [initialGroups, setGroups])

  const handleAccept = async (id) => {
    setGroups(
      groups
        .map(group =>
          group._id === id
            ? { ...group, accepted: !group.accepted }
            : group
        )
    )
    
    await axios.post(`/admin/groups/${id}/accept`, {}, { headers: { Authorization: `Bearer ${auth.token}` } })
  }

  return <List display="flex" gap={5} flexDirection="column">
    {groups?.map(group =>
      <ListItem key={group._id} display="flex" gap={10} justifyContent="space-between">

        <h4>{group.name}</h4>

        <Switch isChecked={group.accepted} onChange={() => handleAccept(group._id)} />

      </ListItem>
    )}
  </List>
}
